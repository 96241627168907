import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby'
// import Img from 'gatsby-image'
import SEO from '../components/seo/SEO'
// import Texte from '../components/Texte'
import Hero from '../components/ui/Hero'
import Modules from '../components/modules'

export const pageQuery = graphql`
  query ModulaireQuery($slug: String!, $locale: String!) {
    sanityPageModulaire(slug: {current: {eq: $slug}}, locale: {eq: $locale}) {
      ...pageModulaire
    }
  }
`

const Modulaire = ({data : { sanityPageModulaire }}) => {
  // const {data: { sanityPage }, errors} = props
  const {
    title,
    // texte,
    seo,
    imageHero,
    modules,
    _rawModules
  } = sanityPageModulaire

  // useEffect(() => {
  //   document.addEventListener("scroll", _onScroll)

  //   return () => document.removeEventListener("scroll", _onScroll)
  // }, [])

  // let prevScrollTop = 0
  // const _onScroll = () => {
  //   // console.log(window.pageYOffset)
  //   if(window.pageYOffset > prevScrollTop){
  //     console.log("down")
  //   }else{
  //     console.log("up")
  //   }
  //   prevScrollTop = window.pageYOffset
  // }


// console.log(modules)
  return (
    <div className="modulaire">
      <SEO
        pageTitle={title}
        pageDescription={seo ? seo.description : ""}
        template="template-modulaire"
        pageBanner={seo && seo.image && seo.image.asset.url
          ? seo.image.asset.url 
          : "https://cdn.sanity.io/images/dyrwy3jp/production/61908d352b0422cb8a1923ed2ff3bd0c2a6a9976-1440x900.jpg"}
        page={true}
        // pathname={location.pathname}
      />

      <Hero 
      image={imageHero.asset.fluid}
      title={title} />

      {/* <div className="container">
        <div className="row">
          <div className="col-md-6 col-md-offset-3 text-center">
            <h1 className="fXL strong">{title}</h1>
          </div>
        </div>
      </div> */}
      {_rawModules && <Modules input={_rawModules.modules} />}
      
    </div>
  )
}

export default Modulaire;