import React from 'react';
import Slider from "react-slick"

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      // style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    ><i></i>
    </div>
  );
}

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      // style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    ><i></i>
    </div>
  );
}

const Carousel = ({input}) => {
  const settings = {
    fade: true,
    speed: 250,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    swipe: true,
    lazyLoad: 'progressive',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: () => {
        //this._afterChange()
    },
    beforeChange: (current, next) => {
        //this._beforeChange(current, next)
    },
  }
// console.log(input)
  return (
    <div className="carousel">
      <Slider {...settings}>
        {input.map((item, i) => (
          <div className="slide" key={i}>
            <img src={item.image.asset.url} alt=""/>
          </div>  
        ))}
      </Slider>
      
    </div>
  );
};

export default Carousel;
