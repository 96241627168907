import React from 'react';
import Texte from "../Texte"

const Team = ({ input }) => {
  // console.log(input)
  return (
    <section className={`module module-team`}>
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 col-xs-12">
            <div className="header fM fSeg orange">
              {input.title}
            </div>
            <div className="column">
              {input.liste.map((item, i) => (
                <div className="item" key={i}>
                  <Texte texte={item.text} />
                </div>
              ))}
            </div>
          </div>
          
          <div className="col-md-2"></div>
        </div>
      </div>
    </section>
  );
};

export default Team;