import React from "react"
import Img from "gatsby-background-image"

const Hero = ({ image, title }) => {
	// console.log(image)
	return (
		<section className="hero ">
			<Img
				// {...image}
				fluid={image}
				Tag="figure"
				style={{
					maxHeight: '604px',
					height: '100vh'
				}}
			/>
			{/* <div className="gradient"></div> */}
			{title &&
			<div className="hero-title psa">
				<h1 className="strong ">
					{title}
				</h1>
			</div>
				
			}
		</section>
	)
}

export default Hero
