import React from "react"
import Img from "gatsby-image"
import Figure from "../Figure"
import Carousel from "../ui/Carousel"

const Images = ({ input }) => {
	// const { direction, reverse } = input.primary
	console.log(input.images)
	return (
		<section className={`module module-images`}>
			<div className="container">
				<div className="row">
					<div className="col-md-1"></div>
					<div className="col-md-10 col-xs-12">
						<Carousel input={input.images} />
					</div>
					<div className="col-md-1"></div>
				</div>
			</div>
		
		</section>
	)
}


export default Images
