import React from "react"
import Img from "gatsby-image"
import Texte from "../Texte"
import Figure from "../Figure"
// import Carousel from "../ui/Carousel"


const TextesImages = ({ input }) => {
	// console.log(input)
	return (
		<section className={`module module-textes-images`}>
			<div className="container">
        <div className="row">
          <div className="col-md-2 hidden-xs"></div>
          <div className="col-md-8 col-xs-12">
						<div className="row">
						{input.images.map((item, i) => (
							<div className="col-md-6 col-xs-12" key={i}>
								<figure>
									<img src={item.image.asset.url} alt=""/>
									<figcaption className="fXS">{item.caption}</figcaption>
								</figure>
							</div>
						))}
          	</div>
          </div>
          <div className="col-md-2 hidden-xs"></div>
        </div>

				<div className="row">
          <div className="col-md-2 hidden-xs"></div>
          <div className="col-md-8 col-xs-12">
						<div className="row">
						{input.textes.map((item, i) => (
							<div className="col-md-6 col-xs-12" key={i}>
								<Texte texte={item.text} />
							</div>
						))}
          	</div>
          </div>
          <div className="col-md-2 hidden-xs"></div>
        </div>
   
      </div>
	

		
		</section>
	)
}


export default TextesImages
