import React, { Fragment } from 'react';

const Liste = ({ input }) => {
  // console.log(input)
  return (
    <section className={`module module-liste no-gutter`}>
      <div className="container">
        <div className="row ">
          <div className="col-md-1 hidden-xs"></div>
          <div className="col-md-10 col-xs-12">
            <div className="outline">

              <div className="header fM">
                <div className="text-center">
                  {input.title}
                </div>
              </div>

              <div className="x xw">
                {input.liste.map((item, i) => (
                  <Fragment key={i}>
                  {/* { i % 2 == 0 && <div className="col-md-1"></div>} */}
                  <div className="col-xs ">
                    <div className="liste-item x">
                      <div className="index fSeg strong">{i + 1}</div>
                      <div className="title">{item}</div>
                    </div>
                  </div>
                  {/* { i % 2 != 0 && <div className="col-md-1"></div>} */}
                  </Fragment>
                ))}
                {/* <div className="col-md-1"></div> */}
              </div>
              <div className="h36 hidden-xs"></div>
            </div>
            
          </div>
          <div className="col-md-1 hidden-xs"></div>
        </div>
   
      </div>
    </section>
  );
};

export default Liste;