import React from "react"
import Textes from "./Textes"
import TexteImages from "./TexteImages"
import TextesImages from "./TextesImages"
import Images from "./Images"
import TexteListe from "./TexteListe"
import TexteListeBleue from "./TexteListeBleue"
import Liste from "./Liste"
import Team from "./Team"
import "./index.scss"

const Modules = ({ input }) => {
  const modules = input.map((module, i) => {
    // console.log(module._type)
    switch (module._type) {
      case "textesUI":
        return <Textes key={i} input={module} />
      case "texteImagesUI":
        return <TexteImages key={i} input={module} />
      case "textesImagesUI":
        return <TextesImages key={i} input={module} />
      case "imagesUI":
        return <Images key={i} input={module} />
      case "texteListeUI":
        return <TexteListe key={i} input={module} />
      case "texteListeBleueUI":
        return <TexteListeBleue key={i} input={module} />
      case "listeUI":
        return <Liste key={i} input={module} />
      case "teamUI":
        return <Team key={i} input={module} />
      default:
        return null
    }
  })

  return <div className="modules">{modules}</div>
}

export default Modules
