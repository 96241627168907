import React from "react"
import Img from "gatsby-image"
import Texte from "../Texte"
import Figure from "../Figure"
import Carousel from "../ui/Carousel"

const Template1 = ({ input }) => (
  <div className="container">
    <div className="row">
			<div className="col-md-8 col-xs-12">
				{input.images.length > 1 && 
					<Carousel input={input.images} />
				}
				{input.images.length === 1 && 
					<figure>
						<img src={input.images[0].image.asset.url} alt=""/>
					</figure>
				}
			</div>

			<div className="col-md-4 col-xs-12">
				<div className="chapo orange fM">
					{input.title}
				</div>
				<Texte texte={input.text} />
			</div>
			
			<div className="col-md-2 hidden-xs"></div>
		</div>
  </div>
)

const Template2 = ({ input }) => (
  <div className="container no-gutter">
		<div className="row ">
			<div className="col-md-1 hidden-xs"></div>

			<div className="col-md-5 col-xs-12">
				<div className="has-bg has-ds">
					<div className="chapo orange fM">
					{input.title}
					</div>
					<Texte texte={input.text} />
				</div>
			</div>

			<div className="col-md-5 col-xs-12">
				{input.images.length > 1 && 
					<Carousel input={input.images} />
				}
				{input.images.length === 1 && 
					// <Img {...input.images[0].image.asset} />
					// <Figure node={input.images[0].image} />
					<img src={input.images[0].image.asset.url} alt=""/>
				}
			</div>
			
			<div className="col-md-1 hidden-xs"></div>
		</div>
  </div>
)

const Template3 = ({ input }) => (
	<div className="container">
		<div className="row">
			<div className="col-md-1 hidden-xs"></div>
			<div className="col-md-10 col-xs-12">
				<div className="row">
					<div className="col-md-1 hidden-xs"></div>
					<div className="col-md-10 col-xs-12">
						<figure>
						<img src={input.images[0].image.asset.url} alt=""/>
						</figure>
					</div>
				</div>
				<div className="row has-bg-gris">
					<div className="col-md-5 col-md-offset-1 col-xs-12">
						<div className="chapo orange fM">
							{input.title}
						</div>
					</div>
					<div className="col-md-5 col-xs-12">
						<Texte texte={input.text} />
					</div>
				</div>
			</div>
			<div className="col-md-1 hidden-xs"></div>
		</div>
		
	</div>
)		

const Template4 = ({ input }) => (
	<>
  <div className="bg ">
		<div className="row">
			<div className="col-md-1 "></div>
			<div className="col-md-11 col-xs-12">
				<div className="bg-bleu"></div>
			</div>
		</div>
	</div>
	<div className="container">
		<div className="row">
			<div className="col-md-2 hidden-xs"></div>
			<div className="col-md-4 col-xs-12">
				<div className="inner">
					<div className="chapo fSeg fM">
						{input.title}
					</div>
					<Texte texte={input.text} />
				</div>
			</div>

			<div className="col-md-5 col-xs-12">
				<img src={input.images[0].image.asset.url} alt=""/>
			</div>
			
			<div className="col-md-1 hidden-xs"></div>
		</div>
  </div>
	</>
)

const TexteImages = ({ input }) => {
	// const { direction, reverse } = input.primary
	// console.log(input)
	const template = input.template[0]
	return (
		<section className={`module module-texte-images ${template}`}>
			
				{template === "template1" && 
					<Template1 input={input} />
				}

				{template === "template2" && 
					<Template2 input={input} />
				}

				{template === "template3" && 
					<Template3 input={input} />
				}

				{template === "template4" && 
					<Template4 input={input} />
				}
		</section>
	)
}


export default TexteImages
