import React from "react"
import Texte from "../Texte"

const TexteListe = ({ input }) => {
	// console.log(input)
	return (
		<section className={"module module-texte-liste"}>
			<div className="container">
        <div className="bg">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <div className="bg-bleu"></div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
				<div className="row">
          <div className="col-md-2 hidden-xs"></div>
          <div className="col-md-4">
            <div className="inner">
            <Texte texte={input.texte} />
            </div>
          </div>
          <div className="col-md-4">
           <div className="inner">
            <div className="subtitle">{input.subtitle}</div>
              <ul>
              {input.liste.map((item, i) => (
                <li className="liste-texte-item" key={i}>
                  <div className="index orange fSeg">0{i + 1}</div>
                  <div className="title fM fSeg">{item.title}</div>
                  <div className="texte">{item.texte}</div>
                </li>
              ))}
              </ul>
           </div>
          </div>
          <div className="col-md-2 hidden-xs"></div>
				</div>
			</div>
		</section>
	)
}

export default TexteListe
