import React from "react"
import Texte from "../Texte"

const TexteListeBleue = ({ input }) => {
	console.log(input)
	return (
		<section className={"module module-texte-liste-bleue noma"}>
			<div className="container">
        <div className="inner">
          <div className="bg ">
            <div className="row">
              <div className="col-md-1 "></div>
              <div className="col-md-10 col-xs-12">
                <div className="bg-bleu"></div>
              </div>
              <div className="col-md-1 "></div>

            </div>
          </div>
          <div className="row">
            <div className="col-md-2  hidden-xs"></div>
            <div className="col-md-10 col-xs-12">
              <div className="chapo fSeg fM">{input.title}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 hidden-xs"></div>
            
            <div className="col-md-8 col-xs-12">
              <ul className="x">
              {input.liste.map( (item, i) => (
                <li className="liste-texte-item" key={i}>
                  <div className="index orange fSeg">0{i + 1}</div>
                  <div className="title fL fSeg ttu">{item.title}</div>
                  <div className="texte">{item.texte}</div>
                </li>
              ))}
              </ul>
            </div>
            <div className="col-md-2 hidden-xs"></div>
          </div>
        </div>
			</div>
		</section>
	)
}

export default TexteListeBleue
